import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { language } from '../core/language';


export default function Manifesto() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid='md' style={{ marginTop: 70 }}>

                <Container className='mt-5 pt-5'>
                    <div id="manifesto" className='container-sticky' style={{ padding: 0 }}>
                        <div className='sticky-col'>
                            <div>
                                <h2>{language.manifesto.title}</h2>
                            </div>
                        </div>
                        <div className='scroll-col'>
                            <h2>{language.manifesto.title1}</h2>
                            <p>{language.manifesto.text1}</p>
                            <br />
                            <h2>{language.manifesto.title2}</h2>
                            <p>{language.manifesto.text2}</p>
                            <br />
                            <h2>{language.manifesto.title3}</h2>
                            <p>{language.manifesto.text3}</p>
                            <br />
                            <h2>{language.manifesto.title4}</h2>
                            <p>{language.manifesto.text4}</p>
                            <br />
                            <h2>{language.manifesto.title5}</h2>
                            <p>{language.manifesto.text5}</p>
                            <br />
                            <h2>{language.manifesto.title6}</h2>
                            <p>{language.manifesto.text6}</p>
                            <br />
                            <h2>{language.manifesto.title7}</h2>
                            <p>{language.manifesto.text7}</p>
                            <br />
                            <h2>{language.manifesto.title8}</h2>
                            <p>{language.manifesto.text8}</p>
                            <br />
                            <h2>{language.manifesto.title9}</h2>
                            <p>{language.manifesto.text9}</p>
                        </div>
                    </div>
                </Container>
                
            </Container>

            <Container fluid className='p-0 mt-5'>
                <div className='dark-slide p-4' id='become-team'>
                    <Container className='mb-4 mt-4'>
                        <div className='bg-gradient-text gradient-3'>
                            <center>
                                <h1>{language.careers.title1}</h1>
                                <p className='lead text-white' dangerouslySetInnerHTML={{ __html: language.careers.text1 }}></p>
                                <br />
                                <Link to="/careers">
                                    <span className='button light'>
                                        {language.careers.explore}
                                    </span>
                                </Link>
                            </center>
                        </div>
                    </Container>
                </div>
            </Container>
        </>
    );
};