import React, { useState, useEffect } from 'react';
import './css/globals.css'
import './css/header.css'
import './css/footer.css'
import './css/blog.css'
import './css/customButton.css'
import './css/searchbar.css'
import './css/sticky.css'
import './css/custom.css'
import './css/home.css'
import './css/contact.css'
import './css/history.css'
import './css/method.css'
import './css/manifesto.css'
import './css/services.css'
import './css/showcase.css'
import './css/homemedia.css'
import './css/analytica.css'
import './css/bohtech.css'
import './css/formafarma.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


/** pages */
import Home from './pages/index';
import NotFound from './pages/not_found';
import Contact from './pages/contact';
import Thanks from './pages/thanks';
import Blog from './pages/blog/index';
import Entry from './pages/entry/index';
import Careers from './pages/careers';
import Sociale from './pages/sociale';
import Manifesto from './pages/manifesto';
import Method from './pages/method';
import ServiceErp from './pages/service/erp';
import ServiceWebAndApp from './pages/service/web-and-app';
import ServicePiattaformeWeb from './pages/service/piattaforme-web';
import ServiceApp from './pages/service/app';
import ServiceSitiCorporate from './pages/service/siti-corporate';
import ServiceMarketing from './pages/service/marketing';
import ServiceEcommerce from './pages/service/ecommerce';
import ServiceEcommerceB2b from './pages/service/ecommerce-b2b';
import ServiceEcommerceB2c from './pages/service/ecommerce-b2c';
import CompanyBohtech from './pages/company/bohtech';
import CompanyFormafarma from './pages/company/formafarma';
import CompanyHomemedia from './pages/company/homemedia';
import CompanyAnalytica from './pages/company/analytica';
import Showcase from './pages/showcase';
import Case from './pages/case/index';
import History from './pages/history';
import Site from './pages/site'; //old url
import Demos from './pages/demos';


/** components */
import HeaderBlock from './components/HeaderBlock';
import FooterBlock from './components/FooterBlock';
import Seo from './components/Seo';
import Pixel from './components/Pixel';
import TagManager from 'react-gtm-module';


function App() {

    library.add(fas)
    library.add(fab)


    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-5WWLL6MM' }); 
    }, []);
    


    return (
        <Router basename={'/'}>
            <Seo />
            
            <HeaderBlock />
            <div id="site-container">
                <Routes>
                    <Route exact path='/' element={<Home />} /> {/* default page */}
                    <Route path="*" element={<NotFound />} /> {/* 404 page */}
                    <Route path='/site' element={<Site />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/thanks' element={<Thanks />} />
                    <Route path="/blog/:p" element={<Blog />} />
                    <Route path="/entry/:k" element={<Entry />} />
                    <Route path='/sociale' element={<Sociale />} />
                    <Route path='/careers' element={<Careers />} />
                    <Route path='/manifesto' element={<Manifesto />} />
                    <Route path='/method' element={<Method />} />

                    <Route path='/service/web-and-app' element={<ServiceWebAndApp />} />
                    <Route path='/service/app' element={<ServiceApp />} />
                    <Route path='/service/piattaforme-web' element={<ServicePiattaformeWeb />} />
                    <Route path='/service/siti-corporate' element={<ServiceSitiCorporate />} />

                    <Route path='/service/ecommerce' element={<ServiceEcommerce />} />
                    <Route path='/service/ecommerce-b2b' element={<ServiceEcommerceB2b />} />
                    <Route path='/service/ecommerce-b2c' element={<ServiceEcommerceB2c />} />

                    <Route path='/service/marketing' element={<ServiceMarketing />} />
                    <Route path='/service/erp' element={<ServiceErp />} />
                    
                    <Route path='/company/homemedia' element={<CompanyHomemedia />} />
                    <Route path='/company/bohtech' element={<CompanyBohtech />} />
                    <Route path='/company/analytica' element={<CompanyAnalytica />} />
                    <Route path='/company/formafarma' element={<CompanyFormafarma />} />
                    <Route path='/showcase' element={<Showcase />} />
                    <Route path="/case/:k" element={<Case />} />
                    <Route path='/history' element={<History />} />
                    <Route path='/demos' element={<Demos />} />
                </Routes>
            </div>
            <FooterBlock />
            
            <Pixel />
        </Router>
    );
   
    
}

export default App;