import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Image, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icoEcommerce from '../../assets/light/e-commerce.svg';
import icoErp from '../../assets/light/erp.svg';
import icoApp from '../../assets/light/app.svg';


export default function ShowcaseHeader() {

    return (
        <Container fluid="md" className='showcaseHeader'>
            
            <Container fluid='md' className='pt-5 pb-5' style={{ position: 'relative' }}>
                
                <Row className='align-items-center'>
                    <Col md={12} lg={4} className='pe-5'>
                        <p style={{ backgroundColor: '#0a4ed4' }} className="marquee">
                            <span>Ecommerce - Novox - Sviluppo - B2B - B2C - Spot - Sito vetrina - Gruppo Yec</span>
                        </p>
                        <h2 style={{ color: '#0a4ed4' }}>Novox</h2>
                        <p>
                            Abbiamo toccato il futuro con mano. Anzi, con le dita. Questo grazie a Novox Touch Pocket, un’idea visionaria di MOSS S.p.A. destinata
                            a mandare in pensione il caro vecchio cerotto.
                        </p>
                        <Link to="/case/12">
                            <span className='button light mt-4 mb-4'>
                                Scopri di più
                            </span>
                        </Link>
                    </Col>
                    <Col md={12} lg={8}>
                        <div className='iframe-vimeo-container'>
                            <iframe className='iframe-vimeo' src="https://player.vimeo.com/video/757089716?h=5895a76628&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Showcase Novox.mp4"></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container fluid='md' className='pt-5 pb-5' style={{ position: 'relative' }}>
                
                <Row className='align-items-center'>
                    <Col md={12} lg={4} className='pe-5'>
                        <p style={{ backgroundColor: '#ab70fc' }} className="marquee">
                            <span>App - Mobile - Quoty - Citazioni - Positivity - Gruppo Yec</span>
                        </p>
                        <h2 style={{ color: '#ab70fc' }}>Quoty</h2>
                        <p>
                            Può un’app essere definita “saggia”? Beh, se si parla di Quoty, sì. Perché conosce migliaia di frasi motivazionali adatte a te e sa sempre quando e come suggerirtele.
                        </p>
                        <Link to="/case/13">
                            <span className='button light mt-4 mb-4'>
                                Scopri di più
                            </span>
                        </Link>
                    </Col>
                    <Col md={12} lg={8}>
                        <div className='iframe-vimeo-container'>
                            <iframe className='iframe-vimeo' src="https://player.vimeo.com/video/757088009?h=641e9613b0&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Showcase Quoty.mp4"></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container fluid='md' className='pt-5 pb-5' style={{ position: 'relative' }}>
                <Row className='align-items-center'>
                    <Col md={12} lg={4} className='pe-5'>
                        <p style={{ backgroundColor: '#6fdc8c' }} className="marquee">
                            <span>ERP - Produzione - MES - Metalinox - Gestionale - Gruppo Yec</span>
                        </p>
                        <h2 style={{ color: '#6fdc8c' }}>Metalinox S.r.l.</h2>
                        <p>
                            Abbiamo concentrato 40 anni di esperienza nel campo dell’acciaio inox e 120 persone in un progetto software abbastanza elastico
                            da rispondere anche alle richieste più rigide.
                        </p>
                        <Link to="/case/22">
                            <span className='button light mt-4 mb-4'>
                                Scopri di più
                            </span>
                        </Link>
                    </Col>
                    <Col md={12} lg={8}>
                        <div className='iframe-vimeo-container'>
                            <iframe className='iframe-vimeo' src="https://player.vimeo.com/video/757089138?h=bf0bb5dcc3&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Showcase Metalinox.mp4"></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>

        </Container>
    );
};