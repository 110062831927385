import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Alert, Spinner } from 'react-bootstrap';
import { ApiManager } from '../../core/api-manager';
import { Helmet } from 'react-helmet';
import CallToAction from '../../components/CallToAction';


export default function Entry() {

    const [errors, setErrors] = useState('');
    const { k } = useParams();
    const id = parseInt(k)

    const [data, setData] = useState(null);


    useEffect(() => {
        init()
    }, [id]);

    
    async function init() {
        let body = {
            id: id
        }
        var response = await ApiManager.sendRequest('/public/get_case', body)
        if(response.success === 1) {
            console.warn(response)
            if(response.body.length > 0) {
                setData(response.body[0])
            }
            else {
                setErrors('Errore. Progetto non trovato.')
            }
        }
        else {
            setErrors('Errore. Progetto non trovato.')
        }
    }



    if (data !== null) {
        return (
            <>
                <Helmet>
                    <title>Case Study - {data.title} | Gruppo Yec</title>
                    <meta name='description' content={data.subtitle}></meta>

                    
                </Helmet>

                <Container fluid style={{ marginTop: 70 }} className='p-0'>
                    <div className='case--page' id='case-study'>
                        <div className='case'>
                            <h1 className='title'>{data.title}</h1>
                            <p className='subtitle'>{data.subtitle}</p>
                            <div className='description'>
                                <table>
                                    <tbody>
                                        <tr>
                                            {data.brief !== '' && data.brief !== null ? (
                                                <td>
                                                    <h6>BRIEF</h6>
                                                    <div dangerouslySetInnerHTML={{ __html: data.brief }}></div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                            {data.solution !== '' && data.solution !== null ? (
                                                <td>
                                                    <h6>SOLUTION</h6>
                                                    <div dangerouslySetInnerHTML={{ __html: data.solution }}></div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='case-content' dangerouslySetInnerHTML={{ __html: data.content }}></div>
                    </div>

                    <CallToAction/>
                    
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mb-5' style={{ marginTop: 120 }}>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="secondary" />
                    <p className='text-secondary'>Loading</p>
                </div>
            </Container>
        )
    }
};