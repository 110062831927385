import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { language } from '../../core/language';
import logo from '../../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function FooterBlock() {

    return (
        <>
            <footer id='footer'>
                <Container fluid='lg' className='text-start'>
                    <Row className='mb-5'>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>{language.footer.title1}</h6>
                            <ul>
                                <li>
                                    <Link to="/">
                                        {language.header.about}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/method">
                                        {language.header.method}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/manifesto">
                                        {language.header.manifesto}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/showcase">
                                        {language.header.showcase}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog/1">
                                        {language.header.blog}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        {language.header.contact}
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>Servizi</h6>
                            <ul>
                                <li>
                                    <Link to="/service/web-and-app">
                                        Web & App
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/service/ecommerce">
                                        E-commerce
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>{language.footer.title2}</h6>
                            <ul>
                                <li>
                                    <Link to="/company/bohtech">
                                        Boh Tech
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/company/homemedia">
                                        Home Media
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/company/analytica">
                                        Yec Analytica
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/company/formafarma">
                                        Forma Farma
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>{language.footer.title4}</h6>

                            <h6>{language.footer.title5}</h6>
                            <p><a href='mailto:info@gruppoyec.com'>info@gruppoyec.com</a></p>
                            <h6>{language.footer.title6}</h6>
                            <p><a href='mailto:press@gruppoyec.com'>press@gruppoyec.com</a></p>
                            <h6>{language.footer.title7}</h6>
                            <p><a href='mailto:info@gruppoyec.com'>info@gruppoyec.com</a></p>
                            
                        </Col>
                    </Row>
                    <hr />
                    <p className='small'>
                        <Link to="/">
                            <Image src={logo} alt='Logo of the site' width={120} height={50} />
                        </Link>
                        <br />
                        GRUPPO YEC SOCIETÀ BENEFIT A R.L.
                        <br/>
                        Corso di Porta Vittoria 29, 20122, Milan, Italy | VAT, tax code, and number of registration with the Milan Monza Brianza Lodi Company Register 01835450493 | REA number MI 2087730
                        <br/>
                        <a href="https://www.iubenda.com/privacy-policy/83067679" target="_blank">Privacy Policy</a> & <a href="https://www.iubenda.com/privacy-policy/83067679/cookie-policy" target="_blank">Cookie Policy</a>
                        <br/>
                    </p>
                    <p className='text-end'>
                        <a target='_blank' href='https://it-it.facebook.com/gruppoyec'>
                            <FontAwesomeIcon size={'lg'} icon={["fab", "facebook"]} className='me-3' />
                        </a>
                        <a target='_blank' href='https://it.linkedin.com/company/young-endeavor-consulting'>
                            <FontAwesomeIcon size={'lg'} icon={["fab", "linkedin"]} className='me-3' />
                        </a>
                        <a target='_blank' href='https://www.instagram.com/gruppoyec/?hl=it'>
                            <FontAwesomeIcon size={'lg'} icon={["fab", "instagram"]} className='me-3' />
                        </a>
                    </p>
                    
                </Container>
            </footer>
        </>
    );
};