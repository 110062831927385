import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { language } from '../core/language';
import icoWeb from '../assets/static/web.svg';
import icoEcommerce from '../assets/static/e-commerce.svg';
import icoErp from '../assets/static/erp.svg';
import icoBlockchain from '../assets/static/blockchain.svg';
import icoMarketing from '../assets/static/marketing.svg';
import icoApp from '../assets/static/app.svg';

import comunita from '../assets/images/comunita.png';
import sociale from '../assets/images/sociale.png';
import foresta from '../assets/images/alberi.jpg';
import api from '../assets/images/api.jpg';
import bg from '../assets/images/copertina.jpg';

import Reviews from '../components/Reviews';


export default function Home() {

    const cache = {};

    function importAll(r) {
        r.keys().forEach((key) => (cache[key] = r(key)));
    }
    importAll(require.context("../assets/customers/", false, /\.(png|jpe?g|svg)$/));

    const logos = Object.entries(cache).map(module => module[1]);


    return (
        <>
            <Container fluid className='p-0' style={{backgroundColor:'#1A2446'}}>
                <div id='slide-bg-home' style={{ backgroundImage: `url(${bg})`, paddingTop: 70 }}>
                    <Container fluid="md" id='slide-home-content'>
                        <h1 dangerouslySetInnerHTML={{ __html: language.home.title1 }}></h1>
                        <p className='lead'>
                            {language.home.text1}
                        </p>
                        <p className='lead'>
                            {language.home.text2}
                        </p>
                    </Container>
                </div>
            </Container>


            <Container fluid className='bg-white' style={{ overflow: 'hidden' }}>
                <Container fluid='md' style={{ marginTop: 70 }}>
                    <p className='text-muted'>I NOSTRI SERVIZI</p>
                    <h2>Di cosa ci occupiamo?</h2>

                    <Row className='mt-5'>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoWeb} className='mb-3' />
                            <h3>{language.home.software}</h3>
                            <p dangerouslySetInnerHTML={{ __html: language.home.softwareText }}></p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoErp} className='mb-3' />
                            <h3>{language.home.industry}</h3>
                            <p dangerouslySetInnerHTML={{ __html: language.home.industryText }}></p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoEcommerce} className='mb-3' />
                            <h3>{language.home.ecommerce}</h3>
                            <p dangerouslySetInnerHTML={{ __html: language.home.ecommerceText }}></p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoApp} className='mb-3' />
                            <h3>{language.home.app}</h3>
                            <p dangerouslySetInnerHTML={{ __html: language.home.appText }}></p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoBlockchain} className='mb-3' />
                            <h3>{language.home.blockchain}</h3>
                            <p dangerouslySetInnerHTML={{ __html: language.home.blockchainText }}></p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoMarketing} className='mb-3' />
                            <h3>{language.home.marketing}</h3>
                            <p dangerouslySetInnerHTML={{ __html: language.home.marketingText }}></p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='mt-5' style={{backgroundColor: '#F4F7FF'}}>
                <Container fluid='md' className='pt-5 pb-5 text-center text-dark'>
                    <h2>
                        Il nostro metodo
                    </h2>
                    <h3 style={{maxWidth: 900}} className='ms-auto me-auto'>
                        Sviluppiamo e gestiamo piattaforme cloud innovative, grazie all’utilizzo di tecnologie sempre all’avanguardia. <span style={{ color: '#418BFF' }}>Pianifichiamo e supportiamo tutte le fasi del processo</span>, dalla strategy allo sviluppo, dalla gestione al marketing, dagli aspetti legali e fiscali alla logistica.
                    </h3>
                    <Link to="/method">
                        <span className='button mt-4'>Scopri di più</span>
                    </Link>
                </Container>
            </Container>


            <Container fluid className='p-0 bg-white' style={{ overflow: 'hidden' }}>
               
                <Reviews />

                <Container fluid='md' className='mt-5 text-center'>
                    <h2>{language.home.customers}</h2>
                    <br />

                    <div className='customers-grid'>
                        {logos.map((logo, i) => {
                            return (
                                <div key={i} className='customer-logo'><Image src={logo} /></div>
                            )
                        })}
                    </div>
                </Container>


                <Container fluid className='mt-5' style={{backgroundColor: '#F4F7FF'}}>
                    <Container fluid='md' className='pt-5 pb-5 text-center text-white'>
                        <h3 style={{maxWidth: 700}} className='ms-auto me-auto text-dark'>
                            Gruppo YEC ha preso la decisione di investire il proprio tempo in <span style={{ color: '#418BFF' }}>iniziative benefiche</span>, con l'obiettivo di promuovere attivamente il contributo al benessere della comunità.
                        </h3>
                        <Link to="/sociale">
                            <span className='button mt-4'>Scopri di più</span>
                        </Link>
                    </Container>
                </Container>


                <Container fluid className='dark-slide'>
                    <div className='pt-5 pb-5' id='environment'>
                        <Container fluid='md' className='text-center text-white'>
                            <h1>{language.home.environment}</h1>
                            <p style={{maxWidth: 700}} className='ms-auto me-auto lead text-white' dangerouslySetInnerHTML={{ __html: language.home.environmentText }}></p>
                        </Container>
                        
                        <Container fluid='md'>
                            <Row>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={language.home.environment1} src={foresta} width='100%' />
                                    <h3 className='text-white mb-0'>{language.home.environment1}</h3>
                                    <p className='text-secondary'>{language.home.environment1Text}</p>
                                </Col>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={language.home.environment2} src={comunita} width='100%' />
                                    <h3 className='text-white mb-0'>{language.home.environment2}</h3>
                                    <p className='text-secondary'>{language.home.environment2Text}</p>
                                </Col>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={language.home.environment3} src={sociale} width='100%' />
                                    <h3 className='text-white mb-0'>{language.home.environment3}</h3>
                                    <p className='text-secondary'>{language.home.environment3Text}</p>
                                </Col>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={language.home.environment4} src={api} width='100%' />
                                    <h3 className='text-white mb-0'>{language.home.environment4}</h3>
                                    <p className='text-secondary'>{language.home.environment4Text}</p>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </Container>
            </Container>
        </>
    )

};