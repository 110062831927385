import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { ApiManager } from '../core/api-manager';
import { language } from '../core/language';
import icoEcommerce from '../assets/light/e-commerce.svg';
import icoErp from '../assets/light/erp.svg';
import icoApp from '../assets/light/app.svg';
import { settings } from '../config/settings';
import CallToAction from '../components/CallToAction';
import ShowcaseHeader from '../components/ShowcaseHeader';


export default function Showcase() {

    const [showcaseActive, setShowcaseActive] = useState(null)


    useEffect(() => {
        if (showcaseActive !== null) {
            getData(1, 100, showcaseActive)
        }
    }, [showcaseActive]);

    useEffect(() => {
        getData(1, 100, "")
    }, []);

    
    const [data, setData] = useState([])
    const [errors, setErrors] = useState('');
    async function getData(p, l, s) {
        let body = {
            page: p - 1,
            type: s
        }
        var response = await ApiManager.sendRequest('/public/get_showcase', body)
        console.warn(response)
        if(response.success === 1) {
            setData(response.body)
        }
        else {
            setErrors('Errore. Riprova più tardi.')
        }
    }



    return (
        <>
            <script
                id="vimeo-js"
                src="https://player.vimeo.com/api/player.js"
            />

            <Container fluid style={{ marginTop: 70, backgroundColor: '#1A2446' }} className='text-white pt-4 pb-4'>
                
                <ShowcaseHeader />

            </Container>
            

            <Container fluid className='bg-white pt-4 mb-5'>
                <Container fluid='md'>

                    <Container className='mt-5 mb-5'>
                        <center>
                            <h1>{language.showcase.title1}</h1>
                            <div className='showcase-nav'>

                                {showcaseActive === 'erp' ? (
                                    <li className='active'>ERP e Industria 4.0</li>
                                ) : (
                                    <li onClick={() => setShowcaseActive('erp')}>ERP e Industria 4.0</li>
                                )}

                                {showcaseActive === 'ecommerce' ? (
                                    <li className='active'>E-commerce</li>
                                ) : (
                                    <li onClick={() => setShowcaseActive('ecommerce')}>E-commerce</li>
                                )}

                                {showcaseActive === 'app' ? (
                                    <li className='active'>Applicazioni</li>
                                ) : (
                                    <li onClick={() => setShowcaseActive('app')}>Applicazioni</li>
                                )}

                                {showcaseActive === 'web' ? (
                                    <li className='active'>Web</li>
                                ) : (
                                    <li onClick={() => setShowcaseActive('web')}>Web</li>
                                )}

                                {showcaseActive === 'marketing' ? (
                                    <li className='active'>Marketing</li>
                                ) : (
                                    <li onClick={() => setShowcaseActive('marketing')}>Marketing</li>
                                )}

                            </div>
                        </center>
                    </Container>


                    {data !== null && data.length > 0 ? (
                        <>
                            <div className='showcase--grid'>
                                {data.map((item, i) => {
                                    let image = encodeURI(settings.ckdPath + 'upload/showcase/' + item.image + '.jpg');
                                    let url = "/case/" + item.id;
                                    return (
                                        <Link to={url} key={i}>
                                            <div className="showcase-item">
                                                {item.image !== null ? (
                                                    <Image src={image} alt={item.title} height={'auto'} width={'100%'} />
                                                ) : (
                                                    <></>
                                                )}
                                                <h5>{item.title}</h5>
                                                <p>{item.subtitle}</p>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <>
                            {data === null ? (
                                <div className='text-center p-5'>
                                    <Spinner animation="border" variant="secondary" />
                                    <p className='text-secondary'>Loading</p>
                                </div>
                            ) : (
                                <Alert variant='warning'>
                                    {language.defaults.noResults}
                                </Alert>
                            )}
                        </>
                    )}

                </Container>

            </Container>

            <CallToAction/>
            
        </>
    );
};