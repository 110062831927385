import React, { useRef } from 'react';
import { Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moss from '../../assets/reviews/moss.png';


import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay, EffectCreative } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function SliderProgettiEcommerce() {

    const prevRef = useRef(null);
    const nextRef = useRef(null);


    return (
        <Container fluid className='mt-5' style={{ backgroundColor: '#eee' }}>
            <Container fluid='md' className='pt-5 pb-5 text-center'>

                <div id="title-with-arrows">
                    <h2>Alcuni progetti</h2>
                    <div id="swiper-navigation-top">
                        <div className='btn-icon' ref={prevRef} id='swiper-left'>
                            <FontAwesomeIcon size={'lg'} icon="chevron-left" />
                        </div>
                        <div className='btn-icon' ref={nextRef}>
                            <FontAwesomeIcon size={'lg'} icon="chevron-right" />
                        </div>
                    </div>
                </div>

                <div className='swiperProgetti'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        modules={[Navigation, Pagination, Scrollbar, Autoplay, EffectCreative]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        grabCursor={true}
                        autoplay={false}
                        navigation={false}
                        pagination={false}
                        scrollbar={false} //scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        //onSlideChange={() => console.log('slide change')}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                    >
                        <SwiperSlide>
                            <Image width={'100%'} src="https://backoffice.gruppoyec.com/ckd/upload/showcase/2e0743721ab8fece76b39a850fde2085.jpg" />
                            <p className='mt-3 muted small'>UGO CACCIATORI</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image width={'100%'} src="https://backoffice.gruppoyec.com/ckd/upload/showcase/6845a6bb8992b2d2a69a0d728cf6a659.jpg" />
                            <p className='mt-3 muted small'>NOVOX</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image width={'100%'} src="https://backoffice.gruppoyec.com/ckd/upload/showcase/869e391e1f291cba646e0184b7e8cdb4.jpg" />
                            <p className='mt-3 muted small'>ISABEL BENENATO</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image width={'100%'} src="https://backoffice.gruppoyec.com/ckd/upload/showcase/c43db5cffbc7725491204d4e25e52141.jpg" />
                            <p className='mt-3 muted small'>LOREDANA PINASCO</p>
                        </SwiperSlide>
                    </Swiper>

                </div>
            </Container>
        </Container>
    );
};