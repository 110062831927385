import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Image, Row, Col } from 'react-bootstrap';
import CallToAction from '../components/CallToAction';
import logos from '../assets/sociale/Logos.jpg';
import buche from '../assets/sociale/Buche.jpg';
import maison from '../assets/sociale/Maison.jpg';



export default function sociale() {

    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h2>
                        Gruppo YEC, consapevole del proprio ruolo nella <span style={{ color: '#418BFF' }}>responsabilità sociale</span>, 
                        ha preso la decisione di investire il proprio tempo in iniziative benefiche, con l'obiettivo di <span style={{ color: '#418BFF' }}>promuovere attivamente il contributo al benessere della comunità</span>. 
                        Questa scelta riflette il profondo impegno dell'azienda verso la creazione di un impatto positivo oltre i confini delle proprie attività commerciali.
                    </h2>
                    <p className='lead mt-5'>
                        Nel perseguire questo obiettivo, l'azienda si impegna attivamente nell'identificare e proporre attività benefiche che rispecchiano appieno i
                        suoi valori e obiettivi. L'attenzione è posta sulla creazione di opportunità che possano avere un impatto significativo sulla comunità,
                        affinché il tempo dedicato si traduca in un contributo tangibile e duraturo al miglioramento delle condizioni di vita nel territorio.
                        <br/><br/>
                        Questa iniziativa rappresenta un importante passo avanti nella missione di Gruppo YEC di essere un agente positivo nella società,
                        dimostrando un impegno concreto nel favorire il bene comune e promuovere uno sviluppo sostenibile a lungo termine. L'azienda è
                        fermamente convinta che la collaborazione attiva con la comunità sia essenziale per costruire un futuro migliore per tutti.
                    </p>
                </Container>
            </Container>


            <Container fluid>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h2>Esploriamo nel dettaglio i progetti già portati a termine.</h2>
                </Container>
            </Container>

            
            <Container fluid className='bg-secondary p-0 mt-5'>
                <Image src={maison} width={'100%'} />
            </Container>
            <Container fluid>
                <Container fluid='md' className='pt-4 pb-5 mb-5'>
                    <h2>Maison des Enfants</h2>
                    <h4 style={{ color: '#418BFF' }}>Il partner</h4>
                    <p>
                        Mainson de enfants è un’Organizzazione di Volontariato già Onlus che si è costituita con l’obiettivo di occuparsi dei “Bambini di Strada” del Senegal.
                        Il progetto "Io Talibè" si propone di offrire un sostegno completo ai bambini, garantendo tutte le necessità primarie essenziali per il loro benessere. Ciò
                        include la fornitura quotidiana di pasti, l'accesso a cure mediche adeguate, la fornitura di indumenti appropriati e l'insegnamento delle regole igieniche
                        fondamentali. Inoltre, il progetto si impegna a intrattenere i bambini attraverso varie attività ludiche, sia di gruppo che individuali, al fine di favorire il loro 
                        sviluppo e rendere la loro esperienza più ricca e stimolante.
                        <br/>
                        Uno degli obiettivi principali è fornire loro gli strumenti necessari per costruire un futuro solido, garantendo opportunità educative e di crescita. Tuttavia, al 
                        di là di queste necessità pratiche, il cuore del progetto consiste nell'offrire a questi bambini tutta l'attenzione e l'affetto di cui hanno bisogno.
                    </p>
                    <h4 style={{ color: '#418BFF' }}>Il progetto</h4>
                    <p>
                        Questa associazione ci ha colpiti profondamente nel cuore e ci siamo sentiti in dovere di contribuire con le nostre esperienze per aiutare questi bambini in
                        difficoltà. Abbiamo creato un Portale Web con aree rivolte ai progetti di Maison de enfants e alle donazioni da fare ai bambini per assicurargli un futuro 
                        migliore. Gestiamo l’intero portale, compresa l’area blog. Mensilmente, pubblichiamo online i contenuti forniti dalla Onlus.
                    </p>
                </Container>
            </Container>


            <Container fluid className='bg-secondary p-0 mt-5'>
                <Image src={buche} width={'100%'} />
            </Container>
            <Container fluid>
                <Container fluid='md' className='pt-4 pb-5 mb-5'>
                    <h2>Buche</h2>
                    <h4 style={{ color: '#418BFF' }}>Il progetto</h4>
                    <p>
                        Buche è un progetto interno a Gruppo Yec, già totalmente sviluppato, che uscirà nel 2024. È un’applicazione sia iOS che Android. Interessati alla
                        sostenibilità e alla sicurezza da sempre, abbiamo progettato un'app che consentirà agli utenti di segnalare le buche lungo le strade di Milano, per la
                        salvaguardia dei cittadini che utilizzano auto, motorini, monopattini o biciclette. È semplice: apri l’app, fotografi la buca e invii la segnalazione che verrà
                        automaticamente geolocalizzata in una mappa. L’utente può aggiungere una descrizione della buca in questione e valutarne il rischio basandosi su 3 livelli: 
                        basso, medio, alto. Pensiamo possa essere davvero un aiuto concreto al Comune di Milano e ai suoi cittadini.
                        <br/>
                        Tutte le segnalazioni fatte dai cittadini verranno inviate al Comune, che le potrà visionare all’interno di un back office dedicato. Qui le buche saranno
                        classificate in base a grandezza e livello di rischio tramite un algoritmo da noi creato, che permette inoltre di raggruppare le diverse segnalazioni fatte alla stessa buca.
                        <br/>
                        Il Comune potrà poi modificare lo status dei lavori per la messa in sicurezza del manto stradale, e questo aggiornamento sarà visibile anche agli utenti dell’app.
                        <br/>
                        Questa progettualità può essere proposta a tutti i comuni italiani.
                    </p>
                </Container>
            </Container>


            <Container fluid className='bg-secondary p-0 mt-5'>
                <Image src={logos} width={'100%'} />
            </Container>
            <Container fluid>
                <Container fluid='md' className='pt-4 pb-5 mb-5'>
                    <h2>Logos e Persona</h2>
                    <h4 style={{ color: '#418BFF' }}>Il partner</h4>
                    <p>
                        Logos e Persona è un’associazione culturale nata nel 2006, in ascolto dell’invito di Papa Benedetto XVI a: “Dilatare i confini della razionalità”.
                        <br/>
                        L’Associazione intende offrire un sostegno ai percorsi di formazione integrale della persona, sia sotto il profilo culturale, che dal punto di vista umano e spirituale. L’Associazione organizza
                        <br/>
                        Incontri di formazione, Pellegrinaggi e Colloqui personali con i sacerdoti.
                    </p>
                    <h4 style={{ color: '#418BFF' }}>Il progetto</h4>
                    <p>
                        Logos e Persona promuove la cultura e la valorizzazione della persona. Abbiamo voluto sostenere l’Associazione e gli Associati tramite l’uso delle nostre tecnologie. Abbiamo 
                        modernizzato il sito istituzionale già esistente dell’Associazione e cambiato l’impatto grafico in base alle loro esigenze. Abbiamo reso più semplice la comunicazione con gli Associati e 
                        integrato sul sito i loro canali social e i loro video da YouTube.
                    </p>
                </Container>
            </Container>


            <CallToAction/>
            
        </>
    );
};